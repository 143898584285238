import { Link } from 'react-router-dom';
import GoogleTranslate from '../components/googleTranslate';

const Header = () => {
  return (
    <div className='theme_bg_container headerSection py-3'>
        <div className='container'> 
            <div className="row">
                <div className='col-2 d-none d-lg-block'></div>
                <div className="col-6 col-lg-8 text-center d-flex align-items-center justify-content-center">
                  <div>
                    <Link to="/">
                      <img
                        src="https://textrepeater.com/_next/image?url=%2Flogo.png&w=384&q=75" 
                        alt="textRepeterLogo" 
                        name="textRepeterLogo"
                        className=' webLogo' 
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-6 col-lg-2 d-flex justify-content-center flex-column navBars">
                  <span className='d-lg-block d-none infoPageOption'><Link to="/whatsapp-tips-tricks">Whatsapp Triks</Link></span>
                  <div><GoogleTranslate /></div>
                </div>
            </div>
        </div>
        <style>{`
            
        `}</style>
    </div>
  )
}

export default Header