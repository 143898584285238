import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
// import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
// import { persistStore } from 'redux-persist';
// import { store, persistor } from './configureStore/store';
import App from "./App";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ScrollToTop } from "./components/scrollUp/scrollToTop";
// import 'bootstrap/dist/js/bootstrap.bundle.min';
ReactDOM.render(
  // <Provider store={store}>
  //   <PersistGate loading={null} persistor={persistor}>
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>,
  //   </PersistGate>
  // </Provider>
  document.getElementById("root")
);
