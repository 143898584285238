import React, { useState } from 'react';
import * as Config from '../config';
import { Adsense } from '@ctrl/react-adsense';
import { connect } from 'react-redux';
import { clearPersistedState } from '../configureStore/lib/storage';
import MyApp from './demo';
// import * as Config from "../lib/config";

const socialData = [
  {
    icon: Config.images.whatsappIcon,
    name: 'Whatsapp',
    url: 'http://whatsapp'
  },
  {
    icon: Config.images.smsIcon,
    name: 'SMS',
    url: 'http://SMS'
  },
  {
    icon: Config.images.messengerIcon,
    name: 'Messenger',
    url: 'http://Messenger'
  },
  {
    icon: Config.images.telegramIcon,
    name: 'Telegram',
    url: 'http://telegram'
  },
]
const Home = ({ counter, increment, decrement }) => {
  const [userText, setUserText] = React.useState('');
  const [numberOfRepeater, setNumberOfRepeater] = React.useState(10);
  const [resultRepeatText, setResultRepeatText] = React.useState('');
  const textAreaRef = React.useRef(null);
  const [settings, setSettings] = React.useState({
    period: false,
    space: false,
    newLine: false,
    dash: false,
    comma: false,
    custom: false
  });
  const [customeSymbol, setCustomeSymbol] = useState('');
  const [tosterMessage, setTosterMessage] = React.useState('')
  const [selectedLink, setSelectedLink] = React.useState({
    icon: Config.images.whatsappIcon,
    name: 'Whatsapp',
    url: 'http://whatsapp'
  });

  const handleButtonClick = (event, copyOption) => {
    navigator.vibrate(50);
    if (textAreaRef.current) {
      textAreaRef.current.select();
      
      if (resultRepeatText.length > 0) {
        if (copyOption) {
          document.execCommand('copy'); // Copy the selected text to the clipboard
          showToaster('Text has been Copied..!');
        }
      } else {
        showToaster('Output is Empty..!');
      }
    }
  };

  const countCharactersAndWords = React.useMemo(() => {
    const characterCount = userText.length;
    const wordCount = userText.split(/\s+/).filter(word => word !== '').length;
    return { characterCount, wordCount };
  }, [userText]);
  
  const repeatText = (text, times) => Array(times).fill(`${text}${settings?.period ? '.' : ''}${settings?.space ? ' ' : ''}${settings?.comma ? ',' : ''}${(customeSymbol?.length > 0 && settings?.custom)  ? customeSymbol : ''}${settings?.dash ? '-' : ''}${settings?.newLine ? '\n' : ''}`).join('');

  const handleRepeatSubmit = () => {
    setResultRepeatText(repeatText(userText, Number(numberOfRepeater)));
    navigator.vibrate(50);
  }

  const showToaster = (message) => {
    setTosterMessage(message);
    const toaster = document.getElementById('toaster');
    toaster.classList.add('show');
    
    setTimeout(() => {
      toaster.classList.remove('show');
    }, 4000);
  }
  
  const handleShareText = () => {
    navigator.vibrate(50);
    shareToSocialMedia(selectedLink.name, resultRepeatText, 'https://play.google.com/store/apps/details?id=com.jkart.textbomber.textrepeater.lovestyles.activity');
  }

  const shareToSocialMedia = (platform, text, url) => {
    if (resultRepeatText.length > 0) {
      const shareUrl = generateShareUrl(platform, text, url);
      if (shareUrl) {
        window.open(shareUrl, '_blank');
      } else {
        console.error('Invalid platform specified.');
      }
    } else {
      showToaster('Output is Empty..!');
    }
  };

  const generateShareUrl = (platform, text, url) => {
    const encodedText = encodeURIComponent(text);
    const encodedUrl = encodeURIComponent(url);
  
    switch (platform) {
      case 'Telegram':
        return `https://t.me/share/url?text=${encodedText}&url=${encodedUrl}`;
      case 'Whatsapp':
        return `https://api.whatsapp.com/send?text=${encodedText}%20${encodedUrl}`;
      case 'SMS':
        return `sms:?body=${encodedText}%20${encodedUrl}`;
      case 'Messenger':
        return `https://www.facebook.com/dialog/send?app_id=123456789&link=${encodedUrl}&redirect_uri=${encodedUrl}`;
      default:
        return '';
    }
  };

  return (
    <>
      <div id="toaster">
        <div className="toasts">{tosterMessage}</div>
      </div>

      {/* <MyApp /> */}

      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-8'>
            <div className='pageTitleText'>Text Repeater</div>
            <div className='webNamesubText col-12 col-md-8'>for WhatsApp, Messenger, SMS, Telegram, and More</div>
          </div>
          <div className='col-12 col-md-4'>
          <Adsense
            client='ca-pub-4272598767949996'
            slot='9414413174'
            style={{ width: '100%', height: 300, float: 'left' }}
            format='auto'
            responsive='true'
            // google_adtest="on"
          />
          </div>
        </div>
        <div className='row py-3'>
          <div className='col-12 col-md-6 py-2 py-md-0'> 
            <div className='boxSection h-100 d-flex flex-column justify-content-between'>
              <div>
                <div className='row pb-2'>
                  <div className='col-6'>Text to repeat</div>
                  <div className='col-6'>
                    <div className='clearOption commanButton' onClick={() => {
                      setUserText('');
                      navigator.vibrate(50);
                    }}>Clear Input</div>
                  </div>
                </div>
                <textarea 
                  type="text" 
                  className="form-control" 
                  placeholder="Text worth repeating goes here"
                  value={userText}
                  onChange={(e) => setUserText(e.target.value)}
                />
                <>
                  <span style={{ paddingRight: '15px'}}>Words: {countCharactersAndWords?.wordCount || 0}</span>
                  <span>Characters: {countCharactersAndWords?.characterCount || 0}</span>
                </>
              </div>
              <div>
                <>
                  <div>Settings</div>
                  <div className='row'>
                    <div className='col-12 col-md-6'>
                      <div>
                        <label className="d-flex align-items-center py-1 cursor-pointer">
                          <input type="checkbox" checked={settings?.period} onChange={(e) => setSettings({ ...settings, period:e.target.checked })} />
                          <span className="pl-2">Add period</span>
                        </label>
                      </div>
                      <div>
                        <label className="d-flex align-items-center py-1 cursor-pointer">
                          <input type="checkbox" checked={settings?.space} onChange={(e) => setSettings({ ...settings, space:e.target.checked })} />
                          <span className="pl-2">Add space</span>
                        </label>
                      </div>
                      <div>
                        <label className="d-flex align-items-center py-1 cursor-pointer">
                          <input type="checkbox" checked={settings?.newLine} onChange={(e) => setSettings({ ...settings, newLine:e.target.checked })} />
                          <span className="pl-2">Add newline</span>
                        </label>
                      </div>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div>
                        <label className="d-flex align-items-center py-1 cursor-pointer">
                          <input type="checkbox" checked={settings?.comma} onChange={(e) => setSettings({ ...settings, comma:e.target.checked })} />
                          <span className="pl-2">Add comma</span>
                        </label>
                      </div>
                      {/* <div>
                        <label className="d-flex align-items-center py-1 cursor-pointer">
                          <input type="checkbox" checked={settings?.dash} onChange={(e) => setSettings({ ...settings, dash:e.target.checked })} />
                          <span className="pl-2">Add dash</span>
                        </label>
                      </div> */}
                      <div>
                        <label className="d-flex align-items-center py-1 cursor-pointer">
                          <input type="checkbox" checked={settings?.custom} onChange={(e) => setSettings({ ...settings, custom:e.target.checked })} />
                          <span className="pl-2">Add custom</span>
                        </label>
                        <input type="text" className="customeSetting" placeholder="Add custom settings" maxlength="8" onChange={(e) => setCustomeSymbol(e.target.value)} />
                      </div>
                    </div>
                  </div>
                </>
                <div className='pt-3'>
                  <div className='pb-1'>Repetitions</div>
                  <div className='row'>
                    <div className='col-3'>
                      <input id="repetitions" onChange={(e) => setNumberOfRepeater(e.target.value.slice(0, 5))} value={numberOfRepeater} type="number" placeholder="Repetitions" className="form-control" />
                    </div>
                    <div className='col-9'>
                      <button id="repeatText" onClick={handleRepeatSubmit} type="button" className="form-control themeButton commanButton">Repeat Text</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-block d-md-none col-12'>
            Ads for mobile devices
          </div>
          <div className='col-12 col-md-6 py-2 py-md-0'> 
            <div className='boxSection h-100'>
              <div className='row pb-2'>
                <div className='col-6'>Output</div>
                <div className='col-6'>
                  <div className='clearOption commanButton' onClick={handleButtonClick}>Select Output</div>
                </div>
              </div>
              <textarea value={resultRepeatText} ref={textAreaRef} rows="8" type="text" className="form-control" readOnly placeholder="Text worth repeating goes here" />
                <div className='col-12 justify-content-end d-flex py-3 shareContainer'>
                  <div className="popover__wrapper" style={{paddingRight: '15px'}}>
                      <h2 className="popover__title d-flex align-items-center cursor-pointer">
                        <div className="iconSection d-flex align-items-center">
                          <img src={selectedLink?.icon}  className="img-fluid" alt="whatsappIcon"/>
                        </div>
                        <span>{selectedLink?.name}</span>
                        <div className="d-flex align-items-center" style={{ width: '19px', paddingTop: '4px'}}>
                          <img src={Config.images.downArrow}  className="img-fluid" name={"downArrow"} alt={"downArrow"} />
                        </div>
                      </h2>
                      <div className="popover__content">
                        {socialData.map((item, index) => (
                          <div key={index} className=' d-flex align-items-center cursor-pointer py-2' onClick={() => setSelectedLink(item)}>
                            <div className="innericonSection d-flex align-items-center">
                              <img src={item.icon}  className="img-fluid" name={item.name} alt={item.name} />
                            </div>
                            <span>{item.name}</span>
                          </div>
                        ))}
                      </div>
                  </div>
                  <buton id="sendBtn" onClick={handleShareText} type="button" className="form-control sendOption commanButton">Send</buton>
                </div>
                <div className="col-12">
                  <buton buton id="sendBtn" onClick={() => handleButtonClick(null, true)} type="button" className="form-control themeButton commanButton text-center">Copy text</buton>
                </div>
            </div>
          </div>
        </div>
        <div className='col-12'>
          Ads for Both devices
        </div>
        <div>
          <div className='webComanTitle'>Welcome to TextRepeater.com</div>
          <div>
            <div className="pages___StyledDiv">🔁 Repeat text and emojis multiple times.</div>
            <div className="pages___StyledDiv">✅ No account and no hassle to use - it's easy and free!</div>
            <div className="pages___StyledDiv">😲 1 million people from 100+ different countries have repeated text.</div>
          </div>
          <p className="Paragraph___Styled">Text Repeater is your go-to app for all things repetition. Our process is simple, just add text, repeat, copy, and share! Whether you want to entertain your friends with endless emojis or drop a full-fledged text bomb, our easy-to-use app is here for all your needs. Plus, you get a built-in word and character counter in your input box.</p>
        </div>
        <div>
            <h2 className="webComanTitle">How Does Text Repeater Work?</h2>
            <p className="Paragraph___StyledP">Our simple web app allows you to endlessly replicate whatever text, emoji, or punctuation you want!</p>
            <ol className="pages___StyledOl">
                <li>Enter your text into the message box and choose your settings.</li>
                <li>Type in how many times you would like your it repeated.</li>
                <li>If you want to add order to your repetition to have some order, you can add a space or period in between your text.</li>
                <li>If you prefer to start your content on a new line, just tap the Add Line option.</li>
                <li>Once ready, you simply hit "Repeat Text" to repeat your text.</li>
            </ol>
            <p className="Paragraph___StyledP">Once you have created your desired output, all that’s left to do is send it!</p>
            <p className="Paragraph___StyledP">
                The web app will let you easily send out the newly replicated content. The side menu will allow you to select or copy your text for easy transference. If you want to immediately spam your friends, the side menu also has a WhatsApp
                option. Here you can output what you’ve created directly through WhatsApp. This will immediately and painlessly send your content to your desired friend or foe.
            </p>
            <br />
        </div>
      </div>
      {/* <h1>Counter: {counter.count}</h1>
      <button onClick={increment}>Increment</button>
      <button onClick={decrement}>Decrement</button>
      <button onClick={clearPersistedState}>Reset</button> */}
    </>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     counter: state.counter,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     increment: () => dispatch({ type: 'INCREMENT' }),
//     decrement: () => dispatch({ type: 'DECREMENT' }),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default (Home);
