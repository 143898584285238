import React, { useEffect } from 'react'
import { clearPersistedState } from '../configureStore/lib/storage';

const About = () => {
  // useEffect(() => {
  //   clearPersistedState();
  // }, [])
  
  return (
    <>
     <div className="container py-2" style={{ height: 'calc(100vh - 429px)' }}>
        <h1 className="pageTitleText">About TextRepeater.com</h1>
        <p className="">
            We make text generation tools saving you time and effort. We also make money by showing you ads. We don't collect any personal information and we don't share any information with third parties.
        </p>
        <div>
            Contact:
            <a href="mailto:03n7bd3a1@relay.firefox.com" className="pl-2">03n7bd3a1 at relay dot firefox dot com</a>
        </div>
        <br />
      </div>

    </>
  )
}

export default About