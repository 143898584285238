import smsIcon from "./static/smsIcon.svg";
import messengerIcon from "./static/messengerIcon.svg";
import telegramIcon from "./static/telegramIcon.svg";
import whatsappIcon from "./static/whatsappIcon.svg";
import downArrow from "./static/downArrow.svg";
import appIcon from "./static/appIcon.png";
import appstore from "./static/appstore.png";
import playstore from "./static/playstore.png";
export const THEME_COLOR = "#27b53d";

export const images = {
  smsIcon,
  messengerIcon,
  whatsappIcon,
  telegramIcon,
  downArrow,
  playstore,
  appstore,
};

export const tipsData = [
  {
    id: 1,
    title: "Font Style",
    image: "https://drive.google.com/uc?id=10cuSDFLWu1qzvjqewzl31CThE5EVt8F6",
    description: [
      "One major problem with writing is that you CAN'T YELL LOOOOUUUDDD ENOUGH...!!! Well, Caps don't do much, right? For this particular reason, WhatsApp allows you to bold the desired font and manipulate the tone of the message as per your wish.",
      "For doing so, you can enclose the desired message within the asterisk/star symbol (*). Moreover, you can transform your message into italics and strikethrough by imprisoning the text in underscore (_) and tilt ease (~) respectively.",
    ],
  },
  {
    id: 2,
    title: "Font Face",
    image: "https://drive.google.com/uc?id=1oUq1aYL1V_0R-rFchu5cwLuNcPk3gMMU",
    description: [
      "Ever felt the need for a better font face that suits the importance of your special message? Here comes one of those coolest WhatsApp tricks!",
      "Fence the desired message within three grave accent symbols (```). The font would then be modified to Consolas form and you’ll get rid of that boring regular font.",
    ],
  },
  {
    id: 3,
    title: "Retain Deleted Media",
    image: "https://drive.google.com/uc?id=1k2DOAOelczKDL-GX6xezITPwQcGuDLFo",
    description: [
      "Worried about that important media that can be deleted by the sender? And then you lose it from your internal storage as well.",
      "No more worries, as this WhatsApp trick would help you scrub off your helplessness from now on! This perfectly efficient glitch will help you to securely store the deleted media in your own internal storage.",
      "And it’s easy as pie! You just have to delete that particular media in the WhatsApp chat without ticking the “delete media from my phone” checkbox. This will leave the media unaffected in your gallery even if it is later deleted by the sender.",
    ],
  },
  {
    id: 4,
    title: "Read Secretly",
    image: "https://drive.google.com/uc?id=1Ur0rZDAE8tWZWgslBMlAjRnGMo0MZMNL",
    description: [
      "Not in a position to reply...but… can’t deal with that accelerating curiosity?",
      "No problem, you can use this effective WhatsApp trick so as to secretly read the message without the sender knowing it.",
      "Turn on the Airplane Mode of your device and sneak into that chat and read them without any worries.",
      "In case, you are looking for a permanent solution kindly let us know too (jokingly).",
      "Well, in such cases, you can go to <strong>Setting &gt; Account &gt; Privacy</strong> and turn “Read Receipts” off for iPhones as well as Androids.",
    ],
  },
  {
    id: 5,
    title: "Discover Your Top Chats",
    image: "https://drive.google.com/uc?id=1KffWBA63t99KEMUFTIlw7tjHPshCr06u",
    description: [
      "Want to know which chat uses the largest storage &amp; data of your device? Well, then use this effective WhatsApp trick to know the complete ranked list of the contacts that used up your device’s memory.",
      "Follow <strong>Setting &gt; Data &amp; Storage Usage &gt; Storage Usage</strong> on Androids as well as iPhones.",
    ],
  },
  {
    id: 6,
    title: "Pin Your Favourite Chats",
    image: "https://drive.google.com/uc?id=10sm21jSMzfgOL1tvSEudfcSLPbbKd4mu",
    description: [
      "Another greatly useful WhatsApp trick is the one that allows you to pin particular chats at the selected places.",
      "This would hold these chats on the desired positions irrespective of the chronology of messages.",
      "To do so, tap &amp; hold the chat and select the ‘pin’ icon.",
    ],
  },
  {
    id: 7,
    title: "Create Event",
    image: "https://drive.google.com/uc?id=1QgqWBYKJnD_vt__4agwewtmkErIOEApM",
    description: [
      "Bad news for the Android users because you CANNOT create events through WhatsApp in any way.",
      "For iPhone users, it’s a piece of cake, just text the date or day for which you desire to create an event.",
      "WhatsApp creates a hyperlink on this message which can thereafter, be clicked to create the event or reminder.",
    ],
  },
  {
    id: 8,
    title: "Enable Pop-Up Previews",
    image: "https://drive.google.com/uc?id=1jr-gI7JDgJ73QYyclsqQfZpF4aaXKwT1",
    description: [
      "Opening Menu, then the WhatsApp application, then the desired chat, and then reading the message, too hectic and boring, isn’t it?",
      "One of those WhatsApp tricks that suit your lethargy very well.",
      "For iPhones, just go to “Settings” and turn on the “Show Previews” option.",
      "Android users would have to follow <strong>Settings &gt; Notifications &gt; Use high priority notifications</strong>. You will immediately see the received message along with a reply box for a timesaving and handy chat option.",
    ],
  },
  {
    id: 9,
    title: "Reply Privately in Groups",
    image: "https://drive.google.com/uc?id=1l3mq0iYt2J8w22hlmmAIdnkQMcku48fc",
    description: [
      "You might have faced some hesitation in replying to a message publically in the group chats. Now, no need to hold on. This WhatsApp trick allows you to reply privately to any group chat message, whether media or text, without being displayed in the group.",
      "To use it, <strong>Long press the desired message &gt; Tap the triple-dot menu at the corner &gt; Select “Reply Privately”</strong>.",
    ],
  },
  {
    id: 10,
    title: "Acquire Information of Your Message",
    image: "https://drive.google.com/uc?id=1O5poQhK_Q_9vZ-piHyj7qtt_jiBs72u8",
    description: [
      "If in case you are highly curious to know the time of delivery of your message and its read time, this WhatsApp trick is meant for you.",
      "Moreover, this trick will also allow you to see the names of the members of the group along with the respective delivery and read time details.",
      "To use this option, just long-press the desired message, tap the three dots, select ‘info’ and discover all the desired details. But remember this works only on the messages that you have sent and not on the ones received.",
    ],
  },
  {
    id: 11,
    title: "Create and Edit GIFs and Images",
    image: "https://drive.google.com/uc?id=1aHyJ_opumkn4d3rvLAULsUytieeSWXip",
    description: [
      "While attaching a video with the chat, WhatsApp offers you to change its media type to GIF as well.",
      "Moreover, while attaching any kind of media WhatsApp features an option to edit it.",
      "You can use a pen, write text and even add a sticker to an image. Whereas, in the case of a video WhatsApp features a video trimmer.",
    ],
  },
  {
    id: 12,
    title: "Group Invitation Permissions",
    image: "https://drive.google.com/uc?id=1HZvzhwADiHB66XF0HNcmzVp-YjpfXavu",
    description: [
      "Irritated with those useless group additions, that drag you in those groups in vain? Thank God, WhatsApp now allows its users to enable the option to ask permission to the users before adding them to any group.",
      "All you have to do is to go to <strong>Setting &gt; Privacy &gt; Groups</strong>and then select the desired option from ‘Everyone’, ‘My Contacts’ and ‘My Contacts except…’ for those especially irritating ones!",
    ],
  },
  {
    id: 13,
    title: "Secure Private Info Against Unknown Numbers",
    image: "https://drive.google.com/uc?id=1--bxcNk4Nw6MRO4o83QnyH4AIK8EXic8",
    description: [
      "WhatsApp is not just a social app, it also deals with a pile of personnel information of each and every user of this social platform. Therefore, it becomes important to secure your data efficiently.",
      "And for this, the WhatsApp developers have supported an option to hide your personal information from the unknown contacts.",
      "To enable this option, go to <strong>Settings &gt; Privacy</strong> and choose the desired options for a Profile photo, About, and Status.",
      "For even more privacy, you can disable the last seen option. But since you can’t have it all at the same time, while disabling your last seen, you are snatched with the opportunity to see others’ last seen as well.",
    ],
  },
  {
    id: 14,
    title: "Disable Auto-Saving Media",
    image: "https://drive.google.com/uc?id=1srZZSeUZPOcv7jgbPAPIC51RTfeuPjzG",
    description: [
      "Imagine that last residue of 50 MB, you are about to click the send email button for the lifesaving email you wrote to your boss and suddenly a video file you just received through WhatsApp sucked it all.",
      "Well, I might have exaggerated a bit, but are you sure you are capable of taking such risks?",
      "So, now through WhatsApp, you can turn off the auto-download media in such cases. Just go to <strong>Settings &gt; Data and Storage Usage &gt; Media Auto-Download</strong> and select the desired option.",
    ],
  },
  {
    id: 15,
    title: "The Big Beating Heart",
    image: "https://drive.google.com/uc?id=1Ut30sXtPIbGvOGm-AP_7cXaxSHTGmUvI",
    description: [
      "Congrats! You just got a bonus WhatsApp trick, and moreover, a highly amazing one.",
      "On the text box, insert the Red Heart emoji and send it without any other attached text or emoticon. What you’ll see would surely impress you a lot.",
      "An appealing large beating heart that could convey beauty along with feelings.",
    ],
  },
];
