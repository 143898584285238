import { tipsData } from "../config"

const WhatsappTriks = () => {
  return (
    <div className="container py-2">
        <main id="main" className="Layout___StyledMain-sc-daah8l-1 hEmzcC">
            <div className="Content___StyledDiv-sc-eco5mv-0 cipkyL">
                <h1 className="">15 Cool WhatsApp Tricks and Tips You Should Know To Up Your Texting Game</h1>
                <p className="Paragraph___StyledP-sc-1e2znrw-0 fPwoYX">
                    Other than repeating texts, there are some neat tricks every WhatsApp user must-know. We have collected 15 of such cool WhatsApp tricks that will turn you into a WhatsApp Wizard.
                </p>
                {tipsData.map((item, index) => (
                    <div key={index} className="Content___StyledDiv2-sc-eco5mv-1 hmILRH">
                        <h3 className="HeadingThree___StyledH-sc-1a1xrug-0 cwFWVd">{index + 1} {item.title}</h3>
                        <div className="Content___StyledDiv3-sc-eco5mv-2 hJvmme">
                            <img
                                alt="whatsapp screenshot"
                                // srcset="/_next/image?url=%2Fimazges%2Ftrick1.jpg&amp;w=640&amp;q=75 1x, /_next/image?url=%2Fimages%2Ftrick1.jpg&amp;w=1200&amp;q=75 2x"
                                src={item.image}
                                decoding="async"
                                data-nimg="1"
                                className="infoImage Content___StyledImage-sc-eco5mv-3 bESFMl"
                                loading="lazy"
                                style={{color : "transparent"}}
                            />
                        </div>
                        {item?.description?.map((desc, index) => (
                            <p className="Paragraph___StyledP-sc-1e2znrw-0 fPwoYX" key={index}>
                                {desc}
                            </p>
                        ))}
                    </div>
                ))}
            </div>
            <div className="Content___StyledDiv34-sc-eco5mv-48 klAbzA">
                <h2 className="HeadingTwo___StyledH-sc-1es3vgf-0 dStohR">Works with WhatsApp and Every Device</h2>
                <p className="Paragraph___StyledP-sc-1e2znrw-0 fPwoYX">
                    The text repeater app can be used on all type of devices with browser and it works well with popular social apps like Whatsapp, Facebook, and more. It is compatible with Android, iOS, Windows, Mac, Linux, etc., operating systems
                    and can easily be accessed using a browser. The upload process is simple. Provided free of charge, all you need to do is visit the website and use it. There is no need to sign up for an account or pay a fee. Our product is
                    provided without the charge and without the hassle!
                </p>
            </div>
            <div className="Content___StyledDiv35-sc-eco5mv-49 dhnVzR">
                <h2 className="HeadingTwo___StyledH-sc-1es3vgf-0 dStohR">Replicate All The Content Your Heart Desires</h2>
                <p className="Paragraph___StyledP-sc-1e2znrw-0 fPwoYX">
                    The website can replicate and generate all desired material. It is compatible with the standard characters of the English Alphabet, characters of ASCII, and your favorite emojis!
                </p>
                <p className="Paragraph___StyledP-sc-1e2znrw-0 fPwoYX">It also does all of this while requiring no storage space. This ensures that your device will not slow down or run out of storage space due as there's nothing to download.</p>
                <p className="Paragraph___StyledP-sc-1e2znrw-0 fPwoYX">Try out <strong>Text Repeater for WhatsApp</strong> now and make sure to share it with your friends and family. We've a handy share button for that at the bottom right.</p>
            </div>
        </main>
    </div>
  )
}

export default WhatsappTriks