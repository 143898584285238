import { Link } from 'react-router-dom';
import * as Config from '../config';

const Footer = () => {
  return (
    <>
      <div className='theme_bg_container py-4' style={{ backgroundColor: `${Config.THEME_COLOR}cc` }}>
        <div className='container'>
          <div className='row'>
            <div className='col-8 col-lg-7'>
              <div className='footerLogoText'>Text Repeater</div>
              <div className='footerSubTitle col-12 col-lg-9'>Simply input text, repeat, copy and share! No fee and no need to create an account. TextRepeater.com is an independent tool and not compensated by or attributed to WhatsApp.</div>
            </div>
            <div className='col-4 col-lg-2 linkSection'>
              <div className='sectionHeading'>Links</div>
              <div><Link to="/">Home</Link></div>
              <div><Link to="/about">About</Link></div>
              <div><Link to="/privacy">Privacy</Link></div>
              <div>Sitemap</div>
            </div>
            <div className='col-12 col-lg-3 socialIconSection'>
              <div className='row m-0 justify-content-center '>
                <div className='col-6 col-lg-12 pb-3 appstore'>
                  <a target="_blank" rel="noreferrer" href='https://apps.apple.com/app/text-repeater-retext/id1672730514'>
                    <img
                      src={Config.images.appstore}
                      alt="right icon"
                      width="100%"
                      className='img-fluid'
                    />
                  </a>
                </div>
                <div className='col-6 col-lg-12 playstore'>
                  <a target="_blank" rel="noreferrer" href='https://play.google.com/store/apps/details?id=com.jkart.textbomber.textrepeater.lovestyles.activity'>
                    <img
                      src={Config.images.playstore}
                      alt="right icon"
                      width="100%"
                      className='img-fluid'
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='theme_bg_container py-3'>
        <div className='container copyRightSection'>
          © 2023 TextRepeater.com
        </div>
      </div>
    </>
  )
}

export default Footer