import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const  ScrollToTop = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      // Scroll to the top of the page when the route changes
      window.scrollTo(0, 0);
    });

    return () => {
      unlisten(); // Cleanup the listener when the component unmounts
    };
  }, [history]);

  return null;
}
